@use 'variables' as *;
.btn {
  font-family: $base-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  border-radius: 0;
  min-height: 56px;
  user-select: none;
  text-align: center;
  padding: 16px 24px;
  transition: .25s ease-in-out;
  &:disabled{
    opacity: .048;
  }
  &-secondary-text,&-primary-text {
    min-height: 40px;
    padding: 8px 12px;
  }
  &-s{
    min-height: 40px;
    padding: 12px 16px;
    line-height: 20px;
    letter-spacing: 0.52px;
    font-size: 13px;
    &-secondary-text,&-primary-text {
      min-height: 36px;

      padding: 6px 8px;
    }
  }
  &-l{
    min-height: 64px;
    padding: 16px 32px;
    line-height: 24px;
    letter-spacing: 0.64px;
    font-size: 16px;
    &-secondary-text,&-primary-text {
      min-height: 48px;
    }
  }
  &:focus{
    outline: 2px solid color('states-border-accent-1_focus');
  }
  &-primary{
      background-color: color('surface/surface-accent-1');
      color: white;
    &:hover, &:active {
      background-color: color('solid-accent-accent-1-accent-1-600');
    }
    &:focus{
      background-color: color('surface/surface-accent-1');
    }
  }
  &-primary-outline, &-primary-text{
    background: transparent;
    color: color('surface/surface-accent-1');
    &:hover, &:active {
      background-color: rgba(202, 106, 83, 0.08);
    }
    &:focus{
      background: transparent;
    }
  }
  &-primary-outline{
    border: 1px solid color('surface/surface-accent-1');
  }
  &-dark-outline, &-dark-text{
    background: transparent;
    color: color('text/text-primary');
    &:hover, &:active {
      background-color: rgba(202, 106, 83, 0.08);
    }
    &:focus{
      background: transparent;
    }
  }
  &-dark-outline{
    border: 1px solid color('text/text-primary');
  }
  &-secondary{
    color: color('surface/surface-accent-1');
    background-color: white;
    &:hover, &:active {
      background-color: color('solid/neutral/neutral-50');
    }
    &:focus{
      background-color: white;
    }
  }
  &-secondary-outline, &-secondary-text{
    background: transparent;
    color: white;

    &:hover, &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:focus{
      background: transparent;
    }
  }
  &-secondary-outline{
    border: 1px solid white;
  }

}

@use 'variables' as *;

.modal-content{
  padding: 24px;
  border-radius: 0;
  @include media-breakpoint-up(md) {
    padding: 48px;
  }
}
.modal {
  &-header {
    position: relative;
    padding: 0;
    border: 0;

  }
  &-footer {
    padding: 48px 0 0;
    border-top: 1px solid #E5E7E8;
    & > *{
      margin: 0;
    }
  }

  &-body {
    padding: 32px 0px;
  }
}

@import 'typography';
@import 'colors';
@import 'background';

.sticky-continue {
  position: sticky;
  bottom: 2rem;
  z-index: 999;
  pointer-events: none;
}

.btn {
  pointer-events: all;
}


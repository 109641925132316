@use "variables" as *;
ngb-carousel {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: flex;
    justify-content: space-between;
  }

  .carousel-control-next,
  .carousel-control-prev {
    background-color: white;
    position: absolute;
    top: 166px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 0;
    border: 1px solid #ca6a53;
    color: #ca6a53;
    text-align: center;
    opacity: 0;

    transition: opacity .35s ease-in-out;
    span {
      justify-content: center;
    }
  }
  &:hover{
    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
    }
  }
  .carousel-control-next:hover {
    color: #ca6a53;
    opacity: 1;
  }

  .carousel-control-prev:hover {
    color: #ca6a53;
    opacity: 1;
  }

  .carousel-control-next {
    right: -32px;
  }

  .carousel-control-prev {
    left: -32px;
  }

  .carousel-control-prev-icon {
    background-image: none;
    display: flex;
    align-items: center;
  }

  .carousel-control-next-icon {
    background-image: none;
    display: flex;
    align-items: center;
  }

  .carousel-control-next-icon::after,
  .carousel-control-prev-icon::after {
    display: block;
    font-size: 1.4rem;
    font-family: font('icomoon');
    color: color('icon/icon-primary');
  }

  .carousel-control-prev-icon::after {
    content: $i-arrow-left;
  }

  .carousel-control-next-icon::after {
    content: $i-arrow-left;
    transform: rotate(180deg);
  }
}

@use 'variables' as *;

@use 'bootstraps';
@use '../global/base/__base';
@use '../global/dmarin-icons-font/dmarin-font-icons.css';
@use '../global/mixins/other';
@use '../global/settings/spaces';
@use 'components/components';
@use 'utils/_utils';
.wrap--default {
  padding: 0 16px;
  margin: auto;
  max-width: 1485px;
  @include bootstraps.media-breakpoint-up(md) {
    padding: 0 24px;
  }
}
html {
  font-size: 1rem;
}

.border-primary {
  border: 1px solid #ca6a53 !important;
}

.border-secondary {
  border: 1px solid #041e42 !important;
}

.btn-fluid {
  @include media-breakpoint-up(md) {
    height: 64px !important;
    width: 100% !important;
  }

  @include media-breakpoint-down(md) {
    height: 56px !important;
    width: 100% !important
  }
}

body{
  background: black;
}
@font-face {
  font-family: 'NeutrafaceText';
  font-style: normal;
  font-weight: 300;
  src: url('^assets/NeutrafaceText-Light.otf');
}

@font-face {
  font-family: 'NeutrafaceText';
  font-style: normal;
  font-weight: 400;
  src: url('^assets/NeutrafaceText-Book.otf');
}
@font-face {
  font-family: 'NeutrafaceText';
  font-style: normal;
  font-weight: 500;
  src: url('^assets/NeutrafaceText-Demi.otf');
}

@font-face {
  font-family: 'NeutrafaceText';
  font-style: normal;
  font-weight: 700;
  src: url('^assets/NeutrafaceText-Bold.otf');
}


@font-face {
  font-family: 'NeutraTextTF';
  font-style: normal;
  font-weight: 300;
  src: url('^assets/NeutraTextTF-LightAlt.otf');
}
@font-face {
  font-family: 'NeutraTextTF';
  font-style: normal;
  font-weight: 400;
  src: url('^assets/NeutraTextTF-BookAlt.otf');
}
@font-face {
  font-family: 'NeutraTextTF';
  font-style: normal;
  font-weight: 500;
  src: url('^assets/NeutraTextTF-DemiAlt.otf');
}
@font-face {
  font-family: 'NeutraTextTF';
  font-style: normal;
  font-weight: 700;
  src: url('^assets/NeutraTextTF-BoldAlt.otf');
}


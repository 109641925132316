@use 'sass:map';
@use '../../global/settings/breakpoints' as *;
@use '../../global/mixins/_mixins' as *;
$headerText: (
  xl: 50px,
  l: 40px,
  m: 32px,
  s: 24px,
  xs: 20px,
) !default;
$subHeaderText: (
  xl: 43px,
  l: 32px,
  m: 24px,
  s: 20px,
) !default;
$bodyText: (
  xl: 20px,
  l: 18px,
  m: 16px,
  s: 14px,
  xs: 11px,
) !default;

@mixin set-text-sizes($sizes, $textName) {

  @each $size, $length in $sizes {

    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
          .#{$textName}-#{$breakpoint}-#{$size} {
            font-size: $length !important;
            letter-spacing: .02em;
        }
      }
    }
    .#{$textName}-#{$size} {
      font-size: $length;
      letter-spacing: .02em;
    }
  }
}


@use 'variables' as *;

.color {
  &-primary {
    color: color('text/text-primary')
  }
  &-secondary {
    color: color('text/text-secondary')
  }
}

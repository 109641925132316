@use 'variables' as *;
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
@import "font-family";
@import "./../settings/typography";
@include set-text-sizes($headerText, 'text-header');
@include set-text-sizes($subHeaderText, 'text-subheader');
@include set-text-sizes($bodyText, 'text-body');
.font-neutral{
  font-family: $base-font-family !important;
}
body{
  font-style: normal;
  font-family: $base-font-family;
  font-weight: 500;
  letter-spacing: 0.96px;
  line-height: 1.4em;
}
p {
  letter-spacing: 0.28px;
}

.text-secondary{
  color: color('text/text-secondary') !important;
}

.text-secondary-strong{
  color: color('text/text-secondary_strong') !important;
}

.body-line-height {
  line-height: 28px !important;
}

.text-primary{
  color: color('surface/surface-accent-1') !important;
}
*[class*='text-header-'] {
  font-weight: 500;
  line-height: 1.4em;
  font-family: $secondary-font-family;
}
*[class*='text-subheader-'] {
  font-weight: 500;
  font-family: $secondary-font-family;
}
